<mp-root-module-nav
  *mpHeaderElement="'left'; let activeModuleName"
  [activeModuleName]="activeModuleName"
  [rootModuleLink]="['/']"
/>

<div class="layout">
  <h3 class="header">Willkommen {{ loginContext().userContext.name }}!</h3>

  <mp-dashboard />
</div>
