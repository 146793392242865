import { Route } from '@angular/router';

import { NavigationGroup, NavigationItem, NavigationStructure } from './navigation-item';
import { redirectToFirstChild } from './redirect-to-first-child';
import { requirePermission } from './require-permission';

export class RouteBuilder {
  private errorRoute?: Route;

  private constructor(private readonly routes: Array<Route>) {}

  static fromRouterConfig(routerConfig: Array<Route>): RouteBuilder {
    return new RouteBuilder([...routerConfig]);
  }

  registerErrorRoute(errorRoute: Route): RouteBuilder {
    this.errorRoute = errorRoute;

    return this;
  }

  registerNavigation(navigationItems: NavigationStructure): RouteBuilder {
    navigationItems.forEach((navigationItem) => this.registerNavigationItem(navigationItem));

    return this;
  }

  buildRoutes(): Array<Route> {
    this.appendErrorRouteDefinitions();

    return this.routes;
  }

  private registerNavigationItem(navigationItem: NavigationGroup | NavigationItem): void {
    if ('children' in navigationItem) {
      this.registerGroupRoutes(navigationItem);
    } else {
      this.registerItemRoute(navigationItem, this.routes);
    }
  }

  private appendErrorRouteDefinitions(): void {
    if (this.errorRoute) {
      this.routes.push(this.errorRoute);
      this.routes.push({
        path: '**',
        redirectTo: this.errorRoute.path,
      });
    }
  }

  private registerGroupRoutes(navigationGroup: NavigationGroup): void {
    const children: Route[] = [];

    const groupRoute: Route = {
      path: navigationGroup.route,
      children,
    };

    navigationGroup.children.forEach((item) => this.registerItemRoute(item, children));

    children.push({
      path: '',
      pathMatch: 'full',
      children: [],
      canActivate: [redirectToFirstChild(navigationGroup)],
    });

    this.routes.push(groupRoute);
  }

  private registerItemRoute(navigationItem: NavigationItem, target: Route[]): void {
    const route: Route = {
      path: navigationItem.route,
      loadChildren: navigationItem.loadChildRoutes,
    };

    if (navigationItem.permission != null) {
      route.canActivate = [requirePermission(navigationItem.permission)];
    }

    target.push(route);
  }
}
